import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import {
  coinsultHero,
  m1,
  m2,
  m3,
  m4,
  m5,
  cgw,
  g1,
} from "../components/SmallComponents/Images";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PresaleBox from "../components/PresaleBox";
import Marquee from "react-fast-marquee";
const handleCopyAddress = () => {
  // Get the contract address
  const contractAddress = "DikK57rESsB8pdEpirCPURcJVLESSS3Gn9R4UWEH1H2W";
  // Copy the address to clipboard
  navigator.clipboard.writeText(contractAddress)
    .then(() => {
      // Address copied successfully
      console.log('Address copied to clipboard:', contractAddress);
    })
    .catch((error) => {
      // Unable to copy address
      console.error('Failed to copy address:', error);
    });
};
function HeroSection() {
  const array = [
    { img: m1 },
    { img: m2 },
    { img: m3 },
    { img: m4 },
    { img: m5 },
  ];
  return (
    <>
      <Box component="section" sx={{ marginTop: "5rem" }}>
        <Container>
          <Grid item>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: "#ffffff",
                  fontSize: { xs: "40px", lg: "52px" },
                  marginBottom: "1rem",
                  lineHeight: "1.25",
                  textAlign: "center"
                }}
              >
                Become an
                 Early Investor
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  lineHeight: "2",
                  color: "rgb(133,133,133)",
                  marginBottom: "4rem",
                }}
              >
Join the groundbreaking movement driving the evolution of media as we know it. Step boldly into the forefront of the blockchain-powered news revolution with BlockPress, where innovation meets integrity and transparency merges with trust. Become an early supporter and visionary witness to the transformative journey of news dissemination. Together, let's shape a future where authenticity and reliability are not just values, but the very fabric of media for generations to come.






</Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "0.75rem",
                  justifyContent: { xs: "center", md: "start" },
                  alignItems: "center",
                  marginBottom: "4rem",
                }}
              >
                {/* <Button
                  sx={{
                    minWidth: {xs: "auto", sm:"180px"},
                    borderRadius: "1rem",
                    padding: {xs:"0.75rem 1rem", sm:"0.75rem"},
                    backgroundColor: "rgb(41,41,41)",
                    color: "#fff",
                    '&:hover':{backgroundColor: "rgb(41,41,41)", opacity: 0.7}
                  }}
                >
                  <Box component="img" src={coinsultHero} alt="coinsult hero" />
                </Button> 
                <Button
                  sx={{
                    minWidth: {xs: "auto", sm:"160px"},
                    padding: "15px 2rem",
                    fontSize: "0.875rem",
                    lineHeight: "1.25rem",
                    backgroundColor: "transparent",
                    color: "#fff",
                    borderRadius: "1rem",
                    border: "1px solid",
                  }}
                >
                  Intro{" "}
                  <PlayCircleFilledIcon
                    sx={{ fontSize: "16px", ml: 1, color: "#1e72a2" }}
                  />
                </Button>*/}
              </Box>
              {/* <Marquee
                className="no-rtl"
                gradient={true}
                gradientWidth={100}
                gradientColor={[0, 0, 0]}
                speed={50}
                pauseOnClick={false}
                pauseOnHover={false}
              >
                <Box sx={{ display: "flex", alignItems: "center", px: 1 }}>
                  {array.map((item, i) => {
                    return (
                      <Box
                        component="img"
                        key={i}
                        src={item.img}
                        alt={item.img}
                        height={75}
                        width={150}
                        style={{
                          height: "65px",
                          width: "140px",
                          objectFit: "contain",
                          marginLeft: "30px",
                        }}
                      />
                    );
                  })}
                </Box>
              </Marquee> */}
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <PresaleBox />
            </Grid> */}
          </Grid>
          <Grid
            container
            sx={{
              mb: 3,
              // marginTop: "150px",
              backgroundColor: "rgb(22,22,22)",
              padding: { xs: "0.5rem 1rem", md: "2rem 2rem" },
              borderRadius: { xs: "2rem", md: "1rem" },
            }}
          >
            <Grid item xs={12} md={6}>
              <Box sx={{display: {xs:'flex', md:'block'}, justifyContent: 'space-between', alignItems: 'center', flexWrap:'wrap'}}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    borderRight: {
                      xs: "unset",
                      md: "thin solid rgba(255,255,255,0.3)",
                    },
                  }}
                >
                  <Box>
                    <Box
                      component="img"
                      sx={{ maxWidth: "35px", mr: 2.5, borderRadius: '50%' }}
                      src={cgw}
                      alt="cgw"
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "rgba(255,255,255,0.3)",
                        fontSize: { xs: "0.675rem", xl: "0.875rem" },
                      }}
                    >
                      CONTRACT ADDRESS
                    </Typography>
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: { xs: "0.775rem", xl: "1.110rem" },
                        display: {xs: 'none', md: 'block'}
                      }}
                    >
                      DikK57rESsB8pdEpirCPURcJVLESSS3Gn9R4UWEH1H2W
                    </Typography>
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: { xs: "0.875rem", xl: "1.125rem" },
                        display: {xs: 'block', md: 'none'}
                      }}
                    >
                      DikK57r...Gn9R4UWEH1H2W
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: {xs:"flex", md:"none"},
                    color: "#ffffff",
                    border: "1px solid rgb(92,92,92)",
                    padding: "10px 10px",
                    borderRadius: "20px",
                    "&:hover": {
                      backgroundColor: "#ffffff",
                      color: "#000000",
                      cursor: "pointer",
                    },
                  }}
                >
                  <ContentCopyIcon
                    sx={{ fontSize: "18px", color: "rgb(92,92,92)" }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ paddingLeft: { xs: 0, md: 4 } }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.3)",
                      fontSize: { xs: "0.675rem", xl: "0.875rem" },
                    }}
                  >
                    NETWORK
                  </Typography>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontSize: { xs: "0.875rem", xl: "1.125rem" },
                    }}
                  >
                    Solana
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                    <a href="https://solscan.io/token/DikK57rESsB8pdEpirCPURcJVLESSS3Gn9R4UWEH1H2W" target="_blank">

                  <Box
                    component="img"
                    sx={{
                      padding: "0.75rem",
                      mr: 2,
                      borderRadius: "50%",
                      maxWidth: "16px",
                      backgroundColor: "rgba(255,255,255, 0.25)",
                      "&:hover": {
                        backgroundColor: "#1e72a2",
                        cursor: "pointer",
                      },
                    }}
                    src={g1}
                    alt="g1"
                  /></a>
                  <Box
                    onClick={handleCopyAddress}

                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      color: "#ffffff",
                      border: "1px solid rgb(92,92,92)",
                      padding: "10px 15px",
                      borderRadius: "20px",
                      "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#000000",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: "0.75rem" }}>
                      COPY ADDRESS
                    </Typography>{" "}
                    <ContentCopyIcon
                      sx={{ fontSize: "18px", ml: 2, color: "inherit" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default HeroSection;
