import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { StyledTextFooter } from "./SmallComponents/AppComponents";
import {
  logo,
  benzinga,
  bitcoin,
  marketwatch,
  yahoo,
} from "./SmallComponents/Images";

import { FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";
import Marquee from "react-fast-marquee";

const arry = [
  {
    icon: <FaTelegramPlane style={{ color: "#ffffff", fontSize: "22px" }} />,
    link: "https://t.me/pancakeswap",
  },
  {
    icon: <FaTwitter style={{ color: "#ffffff", fontSize: "22px" }} />,
    link: "https://twitter.com/PancakeSwap",
  },
  {
    icon: <FaMedium style={{ color: "#ffffff", fontSize: "22px" }} />,
    link: "https://pancakeswap.medium.com/",
  },
];

function Footer() {
  const array = [
    { img: yahoo },
    { img: benzinga },
    { img: marketwatch },
    { img: bitcoin },
  ];
  return (
    <Box mt={6}>
      <Container maxWidth="lg">
        {/* <Box sx={{mb:8, pb:6, borderBottom: '1px solid rgba(255,255,255,0.15)'}}>
          <Marquee
            className="no-rtl"
            gradient={true}
            gradientWidth={100}
            gradientColor={[0, 0, 0]}
            speed={50}
            pauseOnClick={false}
            pauseOnHover={false}
          >
            <Box sx={{ display: "flex", alignItems: "center", px: 1, gap:12 }}>
              {array.map((item, i) => {
                return (
                  <Box
                    component="img"
                    key={i}
                    src={item.img}
                    alt={item.img}
                    height={75}
                    width={150}
                    style={{
                      height: "65px",
                      width: "140px",
                      objectFit: "contain",
                      marginLeft: "30px",
                    }}
                  />
                );
              })}
            </Box>
          </Marquee>
        </Box> */}
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "start" },
            }}
          >
            <Stack gap={2}>
            <a href="blockpress.ai"><StyledTextFooter>Home</StyledTextFooter></a>
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >{/* 
                <StyledTextFooter>Presale</StyledTextFooter>
                <Box
                  className="blob green"
                  sx={{
                    py: 0.5,
                    px: 1,

                    fontFamily: "Rubik",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#000000",
                    borderRadius: "20px",
                  }}
                >
                  Live
                </Box>
              </Box>
              <StyledTextFooter>TEam</StyledTextFooter> */}
              <a href="mailto:contact@blockpress.ai"><StyledTextFooter>Contact</StyledTextFooter></a>
            </Stack>
          </Grid>
          {/* <Grid item xs={6} sm={6} md={4}>
            <Stack gap={1}>
              <Typography
                variant="h2"
                sx={{
                  color: "#858585",
                  fontSize: { xs: "20px", md: "22px" },
                  fontWeight: "500",
                  fontFamily: "Rubik",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                }}
              >
                Documents
              </Typography>
              <StyledTextFooter>Whitepaper</StyledTextFooter>
              <StyledTextFooter>View On Github</StyledTextFooter>
              <Typography
                variant="h2"
                sx={{
                  mt: 2,
                  color: "#858585",
                  fontSize: { xs: "20px", md: "22px" },
                  fontWeight: "500",
                  fontFamily: "Rubik",
                }}
              >
                Solutions
              </Typography>
              <StyledTextFooter>RedAI Core</StyledTextFooter>
              <StyledTextFooter>Real-time Trading</StyledTextFooter>
              <StyledTextFooter>Smart Alerts</StyledTextFooter>
              <StyledTextFooter> Smart Contract Generator</StyledTextFooter>
            </Stack>
          </Grid> */}
          <Grid item xs={6} sm={6} md={4}>
            <Stack gap={2} alignItems={"center"}>
              <Box component={"img"} width="120px" src={logo} />
              <Typography
                variant="body1"
                sx={{
                  mt: 3,
                  color: "#858585",
                  fontSize: { xs: "16px", md: "18px" },
                  fontWeight: "400",
                  fontFamily: "Rubik",
                  textAlign: "center",
                }}
              >
                {/* 101 Sheikh Zayed Rd-Trade Center-DIFC Dubai, United Arab
                Emirates */}
              </Typography>
              {/* <Stack gap={2} flexDirection={"row"} mt={2}>
                {arry.map(({ icon, link }, index) => (
                  <a
                    href={link}
                    target={"_blank"}
                    rel="noreferrer"
                    key={link + index}
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgb(48 48 48)",
                        borderRadius: "50%",
                        cursor: "pointer",
                        "&:hover": {
                          background: "#a20127",
                          transition: "0.3s",
                        },
                      }}
                    >
                      {icon}
                    </Box>
                  </a>
                ))}
              </Stack> */}
            </Stack>
          </Grid>
        </Grid>
        <Stack
          my={5}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 1, md: 0 },
            py: { xs: 5, md: 7 },
            px: 1,
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "center",
            borderTop: "1px solid rgba(255, 255, 255, 0.2)",
            borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#858585",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: "400",
              fontFamily: "Rubik",
              textAlign: "center",
            }}
          >
            ©2024 ― BLOCKPRESS.AI ALL RIGHTS RESERVED
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#858585",
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: "400",
              fontFamily: "Rubik",
              textAlign: "center",
            }}
          >
            FUTURE OF NEWS MEDIA 
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}

export default Footer;
