import React, { useEffect, useState } from "react";
import {
  Container,
  useMediaQuery,
  SwipeableDrawer,
  Button,
  Box,
  Paper,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { logo } from "./SmallComponents/Images";
import { StyledText } from "./SmallComponents/AppComponents";
import { ExampleButton } from "./SmallComponents/StyledWalletButton";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import SolutionMenu from "./NavMenus/SolutionMenu";
import DocumentsMenu from "./NavMenus/DocumentsMenu";
import CommunityMenu from "./NavMenus/CommunityMenu";
import NorthWestIcon from "@mui/icons-material/NorthWest";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#000000 !important",
    borderRight: "1px solid rgba(255,255,255,0.12)",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

export default function Header() {
  const matchesTwo = useMediaQuery("(max-width:1050px)");
  const classes = useStyles();
  const [anchorElSolutuins, setAnchorElSolutuins] = useState(false);
  const [anchorElDocument, setAnchorElDocument] = useState(false);
  const [anchorElCommunity, setAnchorElCommunity] = useState(false);

  const [state, setState] = useState({
    left: false,
  });
  const matches1 = useMediaQuery("(max-width:1279px)");

  useEffect(() => {
    if (!matchesTwo) {
      setAnchorElSolutuins(false);
      setAnchorElDocument(false);
      setAnchorElCommunity(false);
    }
  }, [matchesTwo]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box display="flex" justifyContent="center" my={2}>
        <Box component={"img"} width="60px" src={logo} alt="" />
      </Box>
      <Stack mt={4} mb={4} mx={2} gap={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <StyledText onClick={toggleDrawer(anchor, false)}>Presale</StyledText>
          <Box
            className="blob green"
            sx={{
              py: 0.5,
              px: 1,

              fontFamily: "Rubik",
              fontWeight: "400",
              fontSize: "14px",
              color: "#000000",
              borderRadius: "20px",
            }}
          >
            Live
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <StyledText
            aria-controls="menu-appbar-solutions"
            aria-haspopup="true"
            statusProps={anchorElSolutuins}
            onClick={() => {
              setAnchorElCommunity(false);
              setAnchorElDocument(false);
              setAnchorElSolutuins(!anchorElSolutuins);
            }}
          >
            Solutions{" "}
            <Box
              component={"span"}
              sx={{
                position: "absolute",
                top: "4px",
                right: "3px",
              }}
            >
              {anchorElSolutuins ? (
                <NorthWestIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                  }}
                />
              ) : (
                <SouthEastIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                  }}
                />
              )}
            </Box>
          </StyledText>
          {anchorElSolutuins && (
            <SolutionMenu
              open={anchorElSolutuins}
              setOpen={setAnchorElSolutuins}
              onClickFunction={toggleDrawer(anchor, false)}
            />
          )}
        </Box>
        <StyledText>Team</StyledText>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <StyledText
            statusProps={anchorElDocument}
            onClick={() => {
              setAnchorElCommunity(false);
              setAnchorElSolutuins(false);
              setAnchorElDocument(!anchorElDocument);
            }}
          >
            Documents{" "}
            <Box
              component={"span"}
              sx={{
                position: "absolute",
                top: "4px",
                right: "3px",
              }}
            >
              {anchorElDocument ? (
                <NorthWestIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                  }}
                />
              ) : (
                <SouthEastIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                  }}
                />
              )}
            </Box>
          </StyledText>
          {anchorElDocument && (
            <DocumentsMenu
              open={anchorElDocument}
              setOpen={setAnchorElDocument}
              onClickFunction={toggleDrawer(anchor, false)}
            />
          )}
        </Box>
        <Box
          sx={{
            position: "relative",
          }}
        >
          <StyledText
            statusProps={anchorElCommunity}
            onClick={() => {
              setAnchorElDocument(false);
              setAnchorElSolutuins(false);
              setAnchorElCommunity(!anchorElCommunity);
            }}
          >
            COMMUNITY{" "}
            <Box
              component={"span"}
              sx={{
                position: "absolute",
                top: "4px",
                right: "3px",
              }}
            >
              {anchorElCommunity ? (
                <NorthWestIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                  }}
                />
              ) : (
                <SouthEastIcon
                  fontSize="small"
                  sx={{
                    fontSize: "16px",
                  }}
                />
              )}
            </Box>
          </StyledText>
          {anchorElCommunity && (
            <CommunityMenu
              open={anchorElCommunity}
              setOpen={setAnchorElCommunity}
              onClickFunction={toggleDrawer(anchor, false)}
            />
          )}
        </Box>
        <StyledText>CONTACT</StyledText>
      </Stack>

      <Box mt={5} display="flex" justifyContent="center">
        <ExampleButton />
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          background: "transparent",
          borderBottom: "1px solid rgba(255,255,255,0.12)",
          width: "100%",
          zIndex: 1,
        }}
        width="100%"
        py={2}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box component={"img"} width="60px" src={logo} alt="" />

            {!matchesTwo && (
              <>
                <Stack direction="row" gap={6} alignItems="center">
                  {/* <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <StyledText
                      aria-controls="menu-appbar-solutions"
                      aria-haspopup="true"
                      statusProps={anchorElSolutuins}
                      onMouseEnter={() => {
                        setAnchorElCommunity(false);
                        setAnchorElDocument(false);
                        setAnchorElSolutuins(true);
                      }}
                    >
                      Solutions{" "}
                      <Box
                        component={"span"}
                        sx={{
                          position: "relative",
                          top: "4px",
                          left: "3px",
                        }}
                      >
                        <SouthEastIcon
                          fontSize="small"
                          sx={{
                            fontSize: "16px",
                          }}
                        />
                      </Box>
                    </StyledText>
                    {anchorElSolutuins && (
                      <SolutionMenu
                        open={anchorElSolutuins}
                        setOpen={setAnchorElSolutuins}
                      />
                    )}
                  </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <StyledText>Presale</StyledText>
                    <Box
                      className="blob red"
                      sx={{
                        py: 0.5,
                        px: 1,

                        fontFamily: "Rubik",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#000000",
                        borderRadius: "20px",
                      }}
                    >
                      Coming Soon
                    </Box>
                  </Box>
                  {/* <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <StyledText
                      statusProps={anchorElDocument}
                      onMouseEnter={() => {
                        setAnchorElCommunity(false);
                        setAnchorElSolutuins(false);
                        setAnchorElDocument(true);
                      }}
                    >
                      Documents{" "}
                      <Box
                        component={"span"}
                        sx={{
                          position: "relative",
                          top: "4px",
                          left: "3px",
                        }}
                      >
                        <SouthEastIcon
                          fontSize="small"
                          sx={{
                            fontSize: "16px",
                          }}
                        />
                      </Box>
                    </StyledText>
                    {anchorElDocument && (
                      <DocumentsMenu
                        open={anchorElDocument}
                        setOpen={setAnchorElDocument}
                      />
                    )}
                  </Box>
                  <StyledText>Team</StyledText>
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <StyledText
                      statusProps={anchorElCommunity}
                      onMouseEnter={() => {
                        setAnchorElDocument(false);
                        setAnchorElSolutuins(false);
                        setAnchorElCommunity(true);
                      }}
                    >
                      COMMUNITY{" "}
                      <Box
                        component={"span"}
                        sx={{
                          position: "relative",
                          top: "4px",
                          left: "3px",
                        }}
                      >
                        <SouthEastIcon
                          fontSize="small"
                          sx={{
                            fontSize: "16px",
                          }}
                        />
                      </Box>
                    </StyledText>
                    {anchorElCommunity && (
                      <CommunityMenu
                        open={anchorElCommunity}
                        setOpen={setAnchorElCommunity}
                      />
                    )}
                  </Box> */}
                  <a href="mailto:contact@blockpress.ai"><StyledText>CONTACT</StyledText></a>
                </Stack>
              </>
            )}

            {/* <Box
              display="flex"
              justifyContent={matches1 ? "end" : "space-between"}
              alignItems="center"
            >
              {!matchesTwo ? (
                <ExampleButton />
              ) : (
                <>
                  {["left"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        onClick={toggleDrawer(anchor, true)}
                        style={{ zIndex: 1 }}
                      >
                        <MenuIcon
                          style={{
                            fontSize: "38px",
                            cursor: "pointer",
                            color: "#ffffff",
                          }}
                        />
                      </Button>
                      <Paper>
                        <SwipeableDrawer
                          classes={{ paper: classes.paper }}
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                        >
                          {list(anchor)}
                        </SwipeableDrawer>
                      </Paper>
                    </React.Fragment>
                  ))}
                </>
              )}
            </Box> */}
          </Box>
        </Container>
      </Box>
    </>
  );
}
