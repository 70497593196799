import React, { useState } from "react";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { cwgImage, Telegram } from "../components/SmallComponents/Images";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

function TokenDistribution() {
  const [progressBarForAll, setprogressBarForAll] = useState(36);
  const progressBars = [
    { label: "PRESALE", value: 33.33, count: "10,000,000,000" },
    { label: "TEAM", value: 10, count: "3,000,000,000" },
    { label: "DEX/LISTING", value: 10, count: "3,000,000,000" },
    { label: "DEVELOPMENT", value: 20, count: "6,000,000,000" },
    { label: "COMPANY'S RESERVER", value: 12.66, count: "3,800,000,000" },
    { label: "MARKETING", value: 10, count: "3,000,000,000" },
    { label: "REWARDS/AIRDROPS", value: 4, count: "1,200,000,000" }
];


  const presale = progressBars[0];
  const team = progressBars[1];
  const dexListing = progressBars[2];
  const development = progressBars[3];
  const companyReserve = progressBars[4];
  const marketing = progressBars[5];
  const rewardsAirdrop = progressBars[6];

  return (
    <Box component="section" mt={15}>
      <Container>
        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            justifyContent: "start",
            gap: "2rem",
            alignItems: "center",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "#ffffff",
              fontSize: { xs: "2.75rem", sm: "3.75rem" },
            }}
          >
            Token distribution
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: "rgb(255,255,255,0.5)", fontSize: "1.25rem" }}
          >
            based on Max Supply
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            gap: "2rem",
            alignItems: "center",
            mt: 5,
          }}
        >
          <Box
            sx={{ order: { xs: 2, md: 1 }, width: { xs: "100%", md: "auto" } }}
          >
            <Box
              sx={{
                "&:hover": { backgroundColor: "rgb(28,28,28)" },
                backgroundColor: "transparent",
                padding: "1.25rem",
                borderRadius: "1rem",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.875rem",
                  textAlign: "left",
                  color: "#ffffff",
                }}
              >
                {presale.value}%
              </Box>
              <Box
                sx={{
                  textAlign: "left",
                  color: "#ffffff",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box sx={{ fontSize: "0.875rem" }}>{presale.label}</Box>
                <Box sx={{ fontSize: "0.875rem", color: "rgba(84,84,84)" }}>
                {presale.count}
                </Box>
              </Box>
              <Box>
                <Stack
                  sx={{
                    height: "10px",
                    background: "rgb(36,36,36)",
                    mt: 1.2,
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <Stack
                    height={"100%"}
                    alignItems={"start"}
                    justifyContent={"center"}
                    position={"relative"}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: `${presale.value}%`,
                        borderRadius: "20px",
                        background: "#1e72a2",
                        zIndex: 0,
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                "&:hover": { backgroundColor: "rgb(28,28,28)" },
                backgroundColor: "transparent",
                padding: "1.25rem",
                borderRadius: "1rem",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.875rem",
                  textAlign: "left",
                  color: "#ffffff",
                }}
              >
                {development.value}%
              </Box>
              <Box
                sx={{
                  textAlign: "left",
                  color: "#ffffff",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box sx={{ fontSize: "0.875rem" }}>{development.label}</Box>
                <Box sx={{ fontSize: "0.875rem", color: "rgba(84,84,84)" }}>
                  {development.count}
                </Box>
              </Box>
              <Box>
                <Stack
                  sx={{
                    height: "10px",
                    background: "rgb(36,36,36)",
                    mt: 1.2,
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <Stack
                    height={"100%"}
                    alignItems={"start"}
                    justifyContent={"center"}
                    position={"relative"}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: `${development.value}%`,
                        borderRadius: "20px",
                        background: "#1e72a2",
                        zIndex: 0,
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                "&:hover": { backgroundColor: "rgb(28,28,28)" },
                backgroundColor: "transparent",
                padding: "1.25rem",
                borderRadius: "1rem",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.875rem",
                  textAlign: "left",
                  color: "#ffffff",
                }}
              >
                {dexListing.value}%
              </Box>
              <Box
                sx={{
                  textAlign: "left",
                  color: "#ffffff",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box sx={{ fontSize: "0.875rem" }}>{dexListing.label}</Box>
                <Box sx={{ fontSize: "0.875rem", color: "rgba(84,84,84)" }}>
                  {dexListing.count}
                </Box>
              </Box>
              <Box>
                <Stack
                  sx={{
                    height: "10px",
                    background: "rgb(36,36,36)",
                    mt: 1.2,
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <Stack
                    height={"100%"}
                    alignItems={"start"}
                    justifyContent={"center"}
                    position={"relative"}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: `${dexListing.value}%`,
                        borderRadius: "20px",
                        background: "#1e72a2",
                        zIndex: 0,
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                "&:hover": { backgroundColor: "rgb(28,28,28)" },
                backgroundColor: "transparent",
                padding: "1.25rem",
                borderRadius: "1rem",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.875rem",
                  textAlign: "left",
                  color: "#ffffff",
                }}
              >
                {companyReserve.value}%
              </Box>
              <Box
                sx={{
                  textAlign: "left",
                  color: "#ffffff",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box sx={{ fontSize: "0.875rem" }}>{companyReserve.label}</Box>
                <Box sx={{ fontSize: "0.875rem", color: "rgba(84,84,84)" }}>
                  {companyReserve.count}
                </Box>
              </Box>
              <Box>
                <Stack
                  sx={{
                    height: "10px",
                    background: "rgb(36,36,36)",
                    mt: 1.2,
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <Stack
                    height={"100%"}
                    alignItems={"start"}
                    justifyContent={"center"}
                    position={"relative"}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: `${companyReserve.value}%`,
                        borderRadius: "20px",
                        background: "#1e72a2",
                        zIndex: 0,
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              order: { xs: 1, md: 2 },
              width: { xs: "100%", md: "auto" },
              textAlign: "center",
            }}
          >
            <Box
              component="img"
              sx={{ width: "400px", maxWidth: "100%", borderRadius: "50%" }}
              src={cwgImage}
              alt="cwg Image"
            />
          </Box>
          <Box
            sx={{ order: { xs: 3, md: 3 }, width: { xs: "100%", md: "auto" } }}
          >
            <Box
              sx={{
                "&:hover": { backgroundColor: "rgb(28,28,28)" },
                backgroundColor: "transparent",
                padding: "1.25rem",
                borderRadius: "1rem",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.875rem",
                  textAlign: "left",
                  color: "#ffffff",
                }}
              >
                {marketing.value}%
              </Box>
              <Box
                sx={{
                  textAlign: "left",
                  color: "#ffffff",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box sx={{ fontSize: "0.875rem" }}>{marketing.label}</Box>
                <Box sx={{ fontSize: "0.875rem", color: "rgba(84,84,84)" }}>
                {marketing.count}
                </Box>
              </Box>
              <Box>
                <Stack
                  sx={{
                    height: "10px",
                    background: "rgb(36,36,36)",
                    mt: 1.2,
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <Stack
                    height={"100%"}
                    alignItems={"start"}
                    justifyContent={"center"}
                    position={"relative"}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: `${marketing.value}%`,
                        borderRadius: "20px",
                        background: "#1e72a2",
                        zIndex: 0,
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                "&:hover": { backgroundColor: "rgb(28,28,28)" },
                backgroundColor: "transparent",
                padding: "1.25rem",
                borderRadius: "1rem",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.875rem",
                  textAlign: "left",
                  color: "#ffffff",
                }}
              >
                {rewardsAirdrop.value}%
              </Box>
              <Box
                sx={{
                  textAlign: "left",
                  color: "#ffffff",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box sx={{ fontSize: "0.875rem" }}>{rewardsAirdrop.label}</Box>
                <Box sx={{ fontSize: "0.875rem", color: "rgba(84,84,84)" }}>
                  {rewardsAirdrop.count}
                </Box>
              </Box>
              <Box>
                <Stack
                  sx={{
                    height: "10px",
                    background: "rgb(36,36,36)",
                    mt: 1.2,
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <Stack
                    height={"100%"}
                    alignItems={"start"}
                    justifyContent={"center"}
                    position={"relative"}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: `${rewardsAirdrop.value}%`,
                        borderRadius: "20px",
                        background: "#1e72a2",
                        zIndex: 0,
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Box>
            <Box
              sx={{
                "&:hover": { backgroundColor: "rgb(28,28,28)" },
                backgroundColor: "transparent",
                padding: "1.25rem",
                borderRadius: "1rem",
              }}
            >
              <Box
                sx={{
                  fontSize: "1.875rem",
                  textAlign: "left",
                  color: "#ffffff",
                }}
              >
                {team.value}%
              </Box>
              <Box
                sx={{
                  textAlign: "left",
                  color: "#ffffff",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box sx={{ fontSize: "0.875rem" }}>{team.label}</Box>
                <Box sx={{ fontSize: "0.875rem", color: "rgba(84,84,84)" }}>
                  {team.count}
                </Box>
              </Box>
              <Box>
                <Stack
                  sx={{
                    height: "10px",
                    background: "rgb(36,36,36)",
                    mt: 1.2,
                    borderRadius: "20px",
                    overflow: "hidden",
                  }}
                >
                  <Stack
                    height={"100%"}
                    alignItems={"start"}
                    justifyContent={"center"}
                    position={"relative"}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: `${team.value}%`,
                        borderRadius: "20px",
                        background: "#1e72a2",
                        zIndex: 0,
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Box>
            
          </Box>
        </Box>
        <Box
          mt={5}
          pt={1}
          sx={{
            borderTop: "2px solid #232323",
            borderBottom: "2px solid #232323",
          }}
        >
          <Typography sx={{ my: 6, color: "rgb(133,133,133)" }}>
            TOKEN LOCKUP SCHEDULE
          </Typography>
          <Typography sx={{ mt: 6, mb: 8, color: "#ffffff", fontSize: "1rem" }}>
          In the vibrant landscape of BlockPress, envision a realm where your investments flourish steadily, all the while granting you recurrent rewards. This strategy isn't merely about yields; it's a calculated journey toward a more structured financial horizon. Aligning with BlockPress's mission of fostering transparency and trust in media, our coin's lockup plan initiates with 33% of tokens becoming accessible before the public launch, while the remaining 67% remain locked for a duration of 24 weeks, unlocking weekly at a rate of 2.75%.



          </Typography>
        </Box>
        {/* <Box
          sx={{
            my: 15,
            backgroundColor: "rgb(22,22,22)",
            padding: {xs:"0.5rem 1rem", md:"1.5rem 2.5rem"},
            borderRadius: {xs:"2rem", md:"1rem"},
          }}
        >
           <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: { xs: "flex", md: "block" },
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    borderRight: {
                      xs: "unset",
                      md: "1px solid rgba(255,255,255,0.3)",
                    },
                  }}
                >
                  <Box
                    component="img"
                    sx={{ mr: 3, maxWidth: "45px" }}
                    src={Telegram}
                    alt=""
                  />
                  <Box>
                    <Typography
                      sx={{
                        color: "rgba(255,255,255,0.3)",
                        fontSize: "0.875rem",
                      }}
                    >
                      Official Telegram Channel
                    </Typography>
                    <Typography sx={{ color: "#ffffff", fontSize: "1.125rem" }}>
                      Cogwise ($COGW) - Official
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{display: { xs: "block", md: "none" }, padding: '5px 7px', border: '1px solid rgba(255,255,255,0.15)', borderRadius:'50%', color: '#ffffff', '&:hover': {cursor: 'pointer', backgroundColor: '#ffffff', color: '#000000'}}}>
                  <ArrowOutwardIcon sx={{fontSize: '20px'}} />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pl: 3,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.3)",
                      fontSize: "0.875rem",
                    }}
                  >
                    Members
                  </Typography>
                  <Typography sx={{ color: "#ffffff", fontSize: "1.125rem" }}>
                    ~3,100
                  </Typography>
                </Box>
                <Button
                  sx={{
                    backgroundColor: "transparent",
                    border: "1px solid rgb(92,92,92)",
                    color: "#ffffff",
                    padding: "0.625rem 0.75rem",
                    borderRadius: "2rem",
                    fontSize: "0.75rem",
                    "&:hover": { backgroundColor: "#ffffff", color: "#000000" },
                  }}
                >
                  Join Group
                </Button>
              </Box>
            </Grid>
          </Grid> 
        </Box> */}
      </Container>
    </Box>
  );
}

export default TokenDistribution;
