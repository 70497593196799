import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import EmpoweringSection from "./pages/EmpoweringSection";
import PresaleSchedule from "./pages/PresaleSchedule";
import TokenDistribution from "./pages/TokenDistribution";
import HaveQuestions from "./pages/HaveQuestions";

import Footer from "./components/Footer";
function App() {
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 56) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Header />
      <HeroSection />
      <EmpoweringSection />
      {/* <PresaleSchedule /> */}
      <TokenDistribution />
      <HaveQuestions />
      <Footer />
    </>
  );
}

export default App;
