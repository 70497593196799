import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { PresaleBg } from '../components/SmallComponents/Images';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

function HaveQuestions() {
  return (
    <Box component='section' sx={{ position: 'relative', boxShadow: 'rgb(0, 0, 0) 0px 0px 35px 48px inset'}}>
        <Box component='video' muted loop autoPlay sx={{objectFit: 'cover', width: '100vw', maxWidth: '100%', height: '500px', borderRadius: '10px', boxShadow: 'rgb(0, 0, 0) 0px 0px 35px 48px inset'}}>
            <Box component='source' src={PresaleBg} type='video/mp4' />
        </Box>
        <Box sx={{position: 'absolute', top: '50%', left: '50%', transform:'translate(-50%, -50%)', textAlign: 'center', maxWidth: '450px'}}>
            {/* <Typography sx={{color: 'rgba(255,255,255, 0.5)', fontSize: '0.875rem', textAlign: 'center', marginBottom: '1rem'}}>[ OUR JOURNEY HAS BEGUN ]</Typography> */}
            <Typography sx={{color: '#ffffff', fontSize: '3.5rem', textAlign: 'center', marginBottom: '1rem'}}>Have questions?</Typography>
            <Typography sx={{color: 'rgba(255,255,255, 0.5)', fontSize: '1.125rem', textAlign: 'center'}}>Join the conversation, as your feedback plays a crucial role in shaping our path forward</Typography>
            <Box sx={{marginTop: '2rem'}}>
                <a href="mailto:contact@blockpress.ai"><Button sx={{color: '#ffffff', backgroundColor: '#1e72a2', padding: '15px 2rem', borderRadius: '2rem', fontSize: '14px', mr:2, mb:{xs:2,sm:0}, minWidth: {xs:'100%', sm:'unset', '&:hover': {backgroundColor: '#1e72a2', opacity: 0.7}}}}>Contact Us <ArrowOutwardIcon /></Button></a>
                {/* <Button sx={{color: '#ffffff', backgroundColor: 'transparent', padding: '15px 2rem', borderRadius: '2rem', fontSize: '14px', border: '1px solid #ffffff', minWidth: {xs:'100%', sm:'unset', '&:hover': {backgroundColor: '#ffffff', color:'#000000', opacity: 0.7}}}}>Intro</Button> */}
            </Box>
        </Box>
    </Box>
  )
}

export default HaveQuestions