import { Box } from "@mui/material";
import { useEffect, useState, useRef } from "react";

export const Waves = () => {
  const waveLines = useRef(null);
  const [shouldPlayAnimation, setShouldPlayAnimation] = useState(true);

  const generateLines = () => {
    const svgEl = waveLines;

    if (!svgEl.current) return;

    const randomRange = function (min, max) {
      return ~~(Math.random() * (max - min + 1)) + min;
    };

    const numberOfLines = 13,
      lineDataArr = [];

    const createPathString = function () {
      let completedPath = "",
        comma = ",",
        ampl = 40; // pixel range from 0, aka how deeply they bend

      for (let i = 0; i < numberOfLines; i++) {
        const path = lineDataArr[i];

        const current = {
          x: ampl * Math.sin(path.counter / path.sin),
          y: ampl * Math.cos(path.counter / path.cos),
        };

        const newPathSection =
          "M" +
          // starting point
          path.startX +
          comma +
          path.startY +
          // quadratic control point
          " Q" +
          path.pointX +
          comma +
          (current.y * 1.1).toFixed(3) + // 1.5 to amp up the bend a little
          // center point intersection
          " " +
          (current.x / 2 + path.centerX).toFixed(3) +
          comma +
          (current.y / 3 + path.centerY).toFixed(3) +
          // end point with quadratic reflection (T) (so the bottom right mirrors the top left)
          " T" +
          path.endX +
          comma +
          path.endY;
        path.counter++;

        completedPath += newPathSection;
      }

      return completedPath;
    };

    const createLines = function () {
      const newPathEl = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        ),
        // higher is slower
        minSpeed = 150,
        maxSpeed = 230;

      // create an arr which contains objects for all lines
      // createPathString() will use this array

      for (let i = 0; i < numberOfLines; i++) {
        const lineDataObj = {
          counter: randomRange(1, 500), // a broad counter range ensures lines start at different cycles (will look more random)
          startX: randomRange(-5, -40),
          startY: randomRange(-5, -30),
          endX: randomRange(200, 220), // viewbox = 200
          endY: randomRange(120, 140), // viewbox = 120
          sin: randomRange(minSpeed, maxSpeed),
          cos: randomRange(minSpeed, maxSpeed),
          pointX: randomRange(30, 55),
          centerX: randomRange(90, 120),
          centerY: randomRange(60, 70),
        };

        lineDataArr.push(lineDataObj);
      }

      const animLoop = function () {
        if (!shouldPlayAnimation) {
          svgEl.current.removeChild(newPathEl);
          return;
        }

        newPathEl.setAttribute("d", createPathString());
        requestAnimationFrame(animLoop);
      };

      // once the path elements are created, start the animation loop
      svgEl.current.appendChild(newPathEl);
      animLoop();
    };

    createLines();
  };

  useEffect(() => {
    generateLines();

    return () => setShouldPlayAnimation(false);
  }, []);
  return (
    <>
      <Box
        sx={{
          transform: "rotate(140deg) translateY(0) scale(1.2)",
          zIndex: "0",
          width: "100%",
          position: "relative",
          "::before": {
            display: "block",
            background:
              "linear-gradient(to right, rgb(0, 0, 0) 10%, rgba(0, 0, 0, 0) 30%)",
            zIndex: '30',
            content: '""',
            transform: 'rotate(40deg)',
            position: 'absolute',
            inset: '0px',
            height: '100%',
            width: '100%'
          },
          "::after": {
            display: "block",
            background:
              "linear-gradient(to left, rgb(0, 0, 0) 10%, rgba(0, 0, 0, 0) 30%)",
            zIndex: '30',
            content: '""',
            transform: 'rotate(40deg)',
            position: 'absolute',
            inset: '0px',
            height: '100%',
            width: '100%'
          },
        }}
      >
        <svg
          ref={waveLines}
          className="waves-lines"
          style={{display: 'block', verticalAlign: 'middle', overflow: 'visible', transform: 'translateY(0%) scale(1.1)', marginLeft: 'auto', marginRight: 'auto', width: '100%', height: '546px'}}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 120"
        >
          <defs>
            <linearGradient id="lineGradient">
              <stop offset="0%" stopColor="#1e72a2" />
              <stop offset="100%" stopColor="#0c1729" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </Box>
    </>
  );
};
