import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Waves } from "../components/Waves";

function EmpoweringSection() {
  return (
    <Box component="section">
      <Container>
        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            zIndex: "-1",
            width: "100%",
          }}
        >
          <Waves />
        </Box>
        <Box sx={{ mt: -50, mb:5 }}>
          <Typography variant="h2" sx={{ color: "#ffffff", fontSize: {xs:"2rem", sm:"3rem"}, px:1 }}>
            Empowering Change:
            <br />
            The Vanguard Team in Blockchain , Media and AI
          </Typography>
          <Grid container my={6}>
            <Grid item xs={12} md={6} px={1} mt={2}>
              <Typography sx={{color: 'rgb(133,133,133)'}}>
              BlockPress is fueled by a formidable team of experts spanning blockchain, finance, and technology. With pioneering minds at its core, BlockPress seamlessly integrates blockchain's potential, ensuring transparency, security, and operational efficiency across the platform. This adept team stands as the driving force behind BlockPress's expansion and success in the dynamic realm of digital media on the blockchain.Their collective expertise serves as the bedrock for BlockPress, enabling the platform to navigate the complexities of the digital media landscape with agility and innovation. 







              </Typography>
            </Grid>
            <Grid item xs={12} md={6} px={1} mt={2}>
              <Typography sx={{color: 'rgb(133,133,133)'}}>
              With a deep understanding of blockchain's multifaceted applications, the team pioneers new standards in news dissemination, fostering trust and authenticity in an era rife with misinformation. Their unwavering dedication to excellence propels BlockPress forward, shaping the future of media with each authenticated piece of information distributed through its decentralized network.







              </Typography>
            </Grid>
          </Grid>
            {/* <Button sx={{color: '#ffffff', backgroundColor: 'transparent', fontSize: '0.875rem', fontWeight: '600', borderRadius: '30px',  padding: '10px 30px', border: '1px solid rgba(255,255,255,0.3)'}}>LEARN MORE ABOUT THE TEAM</Button> */}
        </Box>
      </Container>
    </Box>
  );
}

export default EmpoweringSection;
